<template>
 <div :style="getAppStyle()+'width: 100%; height: 100%; position: relative;'">

    <div class='transition' :style='getAppStyle()+getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div :style="'vertical-align: middle; color: '+getFGColor('#000','#66c')+'; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'">Daily Rundown 1.1</div>
		        <br/>
			    <span class='SVcontrolLabel'>2nd Timezone:</span><br/>
			    <GSelect placeholder='choose second timezone' :options="timezones" style='width:100%' v-model="selectedTimezone" @input='loadStation(dayId)'>
			    </GSelect>
			   
			    <div class="BMSection" :style='getBGStyle2()'>
		              <span class='BMSectionDetail smallText'><b>FILTER:</b></span>
		        </div>
		        <span style='display: inline-flex; width: 100%;'>
		        
				<GSelect :options="filterOptions" style='width: 100%;font-size: 9pt;' v-model="filterFor" :multiple="false" @input="processChange()">  
		        </GSelect>
		        </span>
		         
			    <span class='dontWrap smallText bold'>Grid/Program:</span>
			    <GSelect :options="allPrograms" style='font-size: 9pt;' v-model="selectedPrograms" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
				<span class='dontWrap smallText bold'>{{tr('Breaktype')}}:</span>
			    <GSelect :options="allBreaks" style='font-size: 9pt;' v-model="selectedBreaks" :multiple="true" @input="processChange()">  
		        </GSelect>
				
				<span class='dontWrap smallText bold'>{{tr('RatecardType')}}:</span>
			    <GSelect :options="allRateTypes" style='font-size: 9pt;' v-model="selectedRateTypes" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>{{tr('Client')}}:</span>
			    <GSelect :options="allClients" style='font-size: 9pt;' v-model="selectedClients" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>{{tr('Product')}}:</span>
			    <GSelect :options="allProducts" style='font-size: 9pt;' v-model="selectedProducts" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>{{tr('Copy')}}:</span>
			    <GSelect :options="allCopies" style='font-size: 9pt;' v-model="selectedCopyObjects" :multiple="true" @input="copySelected()">
				    <template :style="materialOptStyle(locked)" #option="{ id, label, locked }">
	      					<div z-index="999" :style="materialOptStyle(locked)">{{label}}</div>
	    	   		</template>  
		        </GSelect>

				<button :style='getAppStyle()' :disabled='allPrograms.length==0 || allClients.length==0' class='button myButton' @click="resetFilter()">Clear filter</button>

				<!--
				<button :disabled='allPrograms.length==0 || allClients.length==0' class='button myButton' @click="applyFilter()">Re-apply</button>
				-->
		        <br/>
		        <span class='SVcontrolLabel'>Show empty breaks:</span>
		    	&nbsp;
			    <app-switch v-model="showEmptyBreaks" :checked="showEmptyBreaks" @change='showBreaks()'/><br/>
			    <br/>
			    <span class='dontWrap smallText bold'>Font-Size:</span>
			    
				<vue-slider @change='val => fontChanged(val)' :marks=false min=5 max=14 v-model="fontSize"></vue-slider>	
				<span class='dontWrap smallText bold'>Time (in 100ms) for day-Optimisation: {{(timing4Opt/10)}}s</span>
				<vue-slider :marks=false :min="50" :max="1200" v-model="timing4Opt"></vue-slider>	
				<br/>
				
<!--			    
			    <span class='dontWrap smallText bold'>Set to Status:</span>
				    <GSelect :options="allBookingStats" style='font-size: 9pt;' v-model="setToStatus" :multiple="false">  
				       <template #option="{ id, label }">
	      					<span z-index="999">{{label}}</span>
	    				 </template>
			        </GSelect>
-->			        
			    
			    <span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
			    <hr/> 
			    
			    <button title='add day to screen' :disabled='days.length>2' class='button myButton' :style='getAppStyle()' @click="addDay('day', false, station.id)">Day
			    <mdicon :width="16" name="plus"/>
			    </button>
			    
			    <!-- TIME ANALYSER -->
				<button v-if="!chartId" title='show time consumption charts' :disabled='days.length>2' class='button myButton' :style='getAppStyle()' @click="addDay('chart')"><mdicon :width="16" name="clock-outline" /></button>
				<button v-else title='hide time consumption charts' class='button myButton' :style='getAppStyle()' @click="closeChart(chartId)"><mdicon :width="16" name="clock-outline" /></button>
				
				<!-- QUICK ANALYSER -->
				<button v-if="!chartClientId" title='show summary/Quick analyser' :disabled='days.length>2' class='button myButton' :style='getAppStyle()' @click="addDay('chartClient')"><mdicon :width="16" name="sigma" /></button>
				<button v-else title='hide summary/Quick analyser' class='button myButton' :style='getAppStyle()' @click="closeChartCl(chartClientId)"><mdicon :width="16" name="sigma" /></button>
				
				<!-- CLIPBOARD -->
				<button v-if='clbId==""' title='Show clipboard (all dangling/limbo spots)' class='button myButton' 
				        :disabled="clipBoardLoading || days.length>2" @click="clipBoardLoading=true; addDay('clipboard')" :style='getAppStyle()'><mdicon :width="16" name="clipboard-outline" />
				        <span v-if="clipboard && clipboard.length" title='hide clipboard (all dangling/limbo spots)' class='w3-badge w3-blue'>{{clipboard.length}}</span>
				</button>
				<button v-else @click="closeDay(clbId)" class='button myButton' :style='getAppStyle()'><mdicon :width="16" name="clipboard-outline" />
				        <span v-if="clipboard && clipboard.length" class='w3-badge w3-blue'>{{clipboard.length}}</span>
				        </button>
				<button title='Refresh/Reload all data' :disabled='days.length==0' class='button myButton' :style='getAppStyle()' @click="reload()"><mdicon :width="16" name="reload" /></button>
				 
			</div>
 	</div>
 	
 	<div v-for="x in days" :key="x" class='transition' :style="'float: left; width:'+ (82/days.length)+'%;'"> 
 		
 		<RunDown v-if="type[x] === 'day'" 
 		    :ref="'RD_'+x" :refx="'RD_'+x"
 			@arrangeSpots=arrangeSpots  
 			@moveSpots=moveSpots  
 			@changeState=changeState
 			@statusUpdate=statusUpdate
 			@dateChanged=dateChanged
 			@stationChanged=stationChanged
 			@moveBreak=moveBreak
 			@deleteSpot=deleteSpot
 			@removeCopy=removeCopy
 			@markXChangeable=markXChangeable
 			@setPrice=setPrice
 			@runIBO=breakPositioningOneBreak
 			@doPositioning=doPositioning
 			@doPositioningDayBM=doPositioningDayBM
 			@removeFiller=removeFillerOnDay
 			@doCopyRotation=doCopyRotation
 			:stations=stations
 			:optimizerSettings=optimizerSettings[activeChannelForWidget[x].id]
 			:user=user
 			:clipboard=clbId
 			:fontSize=fontSize
 			:selectedIN=columnsRD
 			@reload=reload
 			@userUpdated=userUpdated
 			@filterCB=filterCB
 			@optimizeCB=optimizeCB
 			@breakToClipboard=breakToClipboard
 			@dayToClipboard=dayToClipboard
 			@runOptimizer=doPositioningDay
 			@setSpotStatus=setSpotStatus
 			:filterFor=filterFor
 			:selectedPrograms=selectedPrograms
 			:selectedClients=selectedClients
 			:selectedBreaks=selectedBreaks
			:selectedRateTypes=selectedRateTypes
 			:selectedProducts=selectedProducts
 			:selectedCopies=selectedCopies
 			:showEmptyBreaks=showEmptyBreaks
 			:update=update[x] 
 			:placed=placed
 			:data=dataSet[x]
 			:station=activeChannelForWidget[x]
 			:date=activeDateForWidget[x]
 			:stateName="dataSet[x].status?dataSet[x].status.shortname:'XXX'"
 			:dayId=x
 			@closeDay=closeDay></RunDown>
 	   <RunDownClipboard v-else-if="type[x] === 'clipboard'"
 			@moveSpots=moveSpots  
 			@deleteSpot=deleteSpot
 			:user=user
 			@userUpdated=userUpdated
 			@preempt=preempt
 			:CBFilter=CBFilter
 			:filterFor=filterFor
 			:selectedIN=columnsCB
 			:selectedPrograms=selectedPrograms
 			:selectedClients=selectedClients
			:selectedRateTypes=selectedRateTypes
 			:selectedBreaks=selectedBreaks
 			:selectedProducts=selectedProducts
 			:selectedCopies=selectedCopies
 			:preemptionReasons=preemptionReasons
 			:preempted=preempted
 			:update=update[x] 
 			:data=clipboard
 			:date=activeDate
 			:dayId=x
 			@closeDay="closeDay(clbId)"></RunDownClipboard>
 		<RunDownChart v-else-if="type[x] === 'chart'"
 		    @dateChanged=dateChanged
 			@stationChanged=stationChanged
 			@changeState=changeState
 			:stations=stations
 			:selectedPrograms=selectedPrograms
 			:selectedClients=selectedClients
 			:selectedBreaks=selectedBreaks
			:selectedRateTypes=selectedRateTypes
 			:selectedProducts=selectedProducts
 			:selectedCopies=selectedCopies
 			:showEmptyBreaks=showEmptyBreaks
 			:update=counter
 			:data=dataSet[x]
 			:station=station
 			:date=activeDate
 			:stateName="dataSet[x].status?dataSet[x].status.shortname:'XXX'"
 			:dayId=x
 			@closeDay=closeChart></RunDownChart>
 	    <RunDownChartClients v-else-if="type[x] === 'chartClient'"
 		    @dateChanged=dateChanged
 			@stationChanged=stationChanged
 			@changeState=changeState
 			@addFilter=addFilter
 			:stations=stations
 			:fontSize=fontSize
 			:selectedPrograms=selectedPrograms
 			:selectedClients=selectedClients
 			:selectedBreaks=selectedBreaks
			:selectedRateTypes=selectedRateTypes
 			:selectedProducts=selectedProducts
 			:selectedCopies=selectedCopies
 			:showEmptyBreaks=showEmptyBreaks
 			:update=counter
 			:data=dataSet[x]
 			:station=station
 			:date=activeDate
 			:stateName="dataSet[x].status?dataSet[x].status.shortname:'XXX'"
 			:dayId=x
 			@closeDay=closeChartCl></RunDownChartClients>
 	</div>
    <ProgressBar v-if="showProgressBar" :generalInfo=pbInfo :action=pbAction @action=action></ProgressBar>
 </div>
</template>	
<script>
import {HTTP, bngAPI, fwAPI, invAPI, gridAPI, userAPI, myLocale, showError} from '@/variables.js';
import {store, restore} from '@/runDown.js';
import { getAppStyle, getBGStyle2, getFGColor, initAppMode } from '@/AppStyle.js';
import RunDown from '@/components/RunDown';
import { tr } from '@/translate.js';
import RunDownChart from '@/components/RunDownChart';
import RunDownChartClients from '@/components/RunDownChartClients';
import RunDownClipboard from '@/components/RunDownClipboard';
import GSelect from '@/components/misc/GSelect';
import Switch from '@/components/Switch';
import ProgressBar from '@/components/ProgressBar';
import 'w3-css/w3.css';
var momentTZ = require('moment-timezone')
var timers = [];
const MAX_CHANGES = 10;
export default {
  name: 'GFWDetailPlanningView',
   
  components : {
   GSelect, RunDown, 'app-switch': Switch, RunDownChart, RunDownChartClients, RunDownClipboard, ProgressBar
  },
  data () {
    return {
      stationId: 0,
      station: {},
      stations: [],
      dataSet: [],
      tz: {},
      noOfDays: 0,
      dayId: "",
      days: [ ],
      type: [ ],
      showDangling: [],
      loadingActive: false,
      selectedTimezone: {},
      selectedStation: {},
      tz2: {},
      diff: 0,
      fontSize: 10,
      preemptionReasons: [],
      preempted: {},
      unplaced: {},
      placed: [],
      optimizerSettings: [],
      timezones: [],
      timezoneId: 0,
      activeDate: {},
      activeDateForWidget: [],
      activeChannelForWidget: [],
      showEmptyBreaks: false,
      data: 0,
      dayStatus: {},
      clipboard: [],
      CBFilter: {},
      update: [],
      controlsRight: false,
      clbId: "",
      chartId: "",
      chartClientId: "",
      counter: 0,
      columnsCB: "",
      columnsRD: "",
	  clipBoardLoading : false,
      timing4Opt: 129,
      //
      filterOptions: [],
      filterFor: "",
      //
      showProgressBar: false,
      pbAction: "",
      pbInfo: "",
      pbChannelId: 0,
      pbDay: null,
      pbOptions: {},
      
      //
      allPrograms: [],
      selectedPrograms: [],
      allCopies: [],
      allCopyIds: [],
      selectedCopies: [],
      selectedCopyObjects: [],
      allClients: [],
      selectedClients: [],
      allBreaks: [],
	  allRateTypes: [],
      selectedBreaks: [],
	  selectedRateTypes: [],
      allProducts: [],
      selectedProducts: [],
      getAppStyle, getFGColor, getBGStyle2, tr
    }
  },
  methods: {
    myLocale() { return myLocale();},
    copy(c)
    {
        c.time = new Date().getTime();
    	return c;
    },
    fontChanged( val)
     {
        this.$set(this, "fontSize", val);
     },
    storeSettings() {
    	let obj = [];
    	for ( let x in this.days)
    	{
    		let d = this.days[x];
    		let type = this.type[ d];
    		let day = this.activeDateForWidget[ d];
    		if ( ! day )
    		{
    			day = this.activeDate;
    		}
            let channel = (this.activeChannelForWidget[ d]) ? this.activeChannelForWidget[ d].id: this.stationId;
    		obj.push({day: d, type: type, date: day, channel: channel})
    	}
    	//alert(JSON.stringify( obj));
    	store( obj);
    },
    addFilter( type, value)
    {
    	if ( type.toLowerCase()==='clientid')
    	{
    		if ( this.selectedClients.includes( value))
    		{
    			this.selectedClients = this.selectedClients.filter( p=>p !== value)
    		}
    		else
    		{
    			this.selectedClients.push( value);
    		}
    	}
    	/*
    	 that.selectedPrograms = new Array();
     	that.selectedCopies = new Array();
     	that.selectedClients = new Array();
     	that.selectedProducts = new Array();
     	that.selectedBreaks = new Array();
     	*/
     	if ( type.toLowerCase()==='gridid')
    	{
    		if ( this.selectedPrograms.includes( value))
    		{
    			this.selectedPrograms = this.selectedPrograms.filter( p=>p !== value)
    		}
    		else
    		{
    			this.selectedPrograms.push( value);
    		}
    	}
     	if ( type.toLowerCase()==='breaktypeid')
    	{
    		if ( this.selectedBreaks.includes( value))
    		{
    			this.selectedBreaks = this.selectedBreaks.filter( p=>p !== value)
    		}
    		else
    		{
    			this.selectedBreaks.push( value);
    		}
    	}
		if ( type.toLowerCase()==='ratetypeid')
    	{
    		if ( this.selectedRateTypes.includes( value))
    		{
    			this.selectedRateTypes = this.selectedRateTypes.filter( p=>p !== value)
    		}
    		else
    		{
    			this.selectedRateTypes.push( value);
    		}
    	}
    	if ( type.toLowerCase()==='productid')
    	{
    		if ( this.selectedProducts.includes( value))
    		{
    			this.selectedProducts = this.selectedProducts.filter( p=>p !== value)
    		}
    		else
    		{
    			this.selectedProducts.push( value);
    		}
    	}
    	if ( type.toLowerCase()==='copyid')
    	{
    		if ( this.selectedCopies.includes( value))
    		{
    			this.selectedCopies = this.selectedCopies.filter( p=>p !== value)
    		}
    		else
    		{
    			this.selectedCopies.push( value);
    		}
    	}
    },
    closeDay(day)
 	{
    	 const index = this.days.indexOf(day);
			if (index > -1) {
			   this.days.splice(index, 1);
			}
		if ( day == this.clbId )
		{
			this.clbId = "";
		}
		
		this.$set(this, "CBFilter", null);
		if ( this.clbId )
    	{
    		this.loadClipboard(this.clbId)
    	}
		this.dayId = this.days[ this.days.length-1];
		this.clipBoardLoading = false;
    },
    closeChart(day)
 	{
    	 const index = this.days.indexOf(day);
			if (index > -1) {
			   this.days.splice(index, 1);
			}
		if ( day == this.clbId )
		{
			this.clbId = "";
		}
		this.chartId=null;
		this.$set(this, "CBFilter", null);
		if ( this.clbId )
    	{
    		this.loadClipboard(this.clbId)
    	}
		this.dayId = this.days[ this.days.length-1];
    },
    closeChartCl(day)
 	{
    	 const index = this.days.indexOf(day);
			if (index > -1) {
			   this.days.splice(index, 1);
			}
		if ( day == this.clbId )
		{
			this.clbId = "";
		}
		this.chartClientId=null;
		this.$set(this, "CBFilter", null);
		if ( this.clbId )
    	{
    		this.loadClipboard(this.clbId)
    	}
		this.dayId = this.days[ this.days.length-1];
    },
    addDay( type, noload, stationId)
    {
    	this.noOfDays++;
    	let id = "Day" + (this.noOfDays);
    	this.dayId = id;
    	this.update[ id ] = 0;
    	//alert(this.dataSet.length+"/"+this.days.length)
    	if ( (type === "clipboard") )
    	{
    		this.loadClipboard( id, stationId );
    		this.type[ id ] = type;
    		this.activeDate = new Date();
    	}
    	else if ( (type === "day") )
    	{
	    	if ( this.days.length > 0 && !Array.isArray(this.dataSet[ this.days[ this.days.length-1]]))
	    	{
	    	    if ( ! noload )
	    	    {
	    	    	this.dataSet[ id ] = JSON.parse(JSON.stringify( this.dataSet[ this.days[ this.days.length-1]]));
	    	    	this.activeChannelForWidget[ id ] = JSON.parse(JSON.stringify( this.activeChannelForWidget[ this.days[ this.days.length-1]]));
	    	    	this.activeDateForWidget[ id ] = this.activeDate;
	    	    }
	    	    else
	    	    {
	    	    	this.dataSet[ id ] = {};
	    	    }
	    		this.station = this.dataSet[ id].media;
	    		
	    		this.dayStatus = this.dataSet[ id].status;
	    		
	    	}
	    	else if ( ! noload )
	    	{
	    	    this.loadStation( this.dayId );
	    	}
	    }
	    else if ( (type === "chart") )
    	{
	    	this.chartId=id;
	    	if ( this.days.length > 0 && !Array.isArray(this.dataSet[ this.days[ this.days.length-1]]))
	    	{
	    	    this.dataSet[ id ] = {};
	    	    if ( ! noload )
	    	    {
	    	    	this.dataSet[ id ] = JSON.parse(JSON.stringify( this.dataSet[ this.days[ this.days.length-1]]));
	    		}
	    		this.station = this.dataSet[ id].media;
	    		this.dayStatus = this.dataSet[ id].status;
	    	}
	    	else if ( ! noload )
	    	{
	    		this.loadStation( this.dayId );
	    	}
	    	
	    }
	    else if ( (type === "chartClient") )
    	{
	    	this.chartClientId=id;
	    	if ( this.days.length > 0 && !Array.isArray(this.dataSet[ this.days[ this.days.length-1]]))
	    	{
	    	    this.dataSet[ id ] = {};
	    	    if ( ! noload )
	    	    {
	    	    	this.dataSet[ id ] = JSON.parse(JSON.stringify( this.dataSet[ this.days[ this.days.length-1]]));
	    		}
	    		this.station = this.dataSet[ id].media;
	    		this.dayStatus = this.dataSet[ id].status;
	    	}
	    	else if ( ! noload )
	    	{
	    		this.loadStation( this.dayId );
	    	}
	    	
	    }
    	this.days.push( id );
	    this.type[ id ] = type;
	    this.counter++;
	    return id;
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vh - 140px); padding: 2ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vh - 140px); padding: 2ex;"
    },
    materialOptStyle(lock)
    {
    	if ( lock )
    	{
    		return "color: #000; font-weight: bold; font-size: 8pt; margin: 0pt !important; height: 100% !important; width: 100; padding: 0pt; background-color: #fcc;";
    	}
    	return "font-size: 8pt;";
    },
    stationChanged( dayId, channelID) 
    {
    	let that=this;
        this.activeDate = new Date( this.activeDate);
    	this.loadStation(dayId, channelID, this.activeDateForWidget[ dayId]).then( p => {that.counter++; that.activeDate = new Date( that.activeDate);})
    },
    dateChanged(dayId, newDate) {
    	let that=this;
    	let dateISO = momentTZ(newDate).utcOffset(0, true).format().split('T')[0];
    	//alert("dateChanged("+dayId+","+dateISO+")")
    	this.activeDateForWidget[ dayId] = new Date( dateISO);
		this.loadStation( dayId, null, this.activeDateForWidget[ dayId]).then( p => {that.activeDate = new Date( dateISO);})
		
    },
    reload()
    {
    	let dayId = this.days[0];
        let newDate = this.activeDateForWidget[ dayId];
    	this.activeDateForWidget[ dayId] = newDate;
    	this.loadStation( dayId, this.activeChannelForWidget[dayId].id, newDate);
    },
    arrangeSpots (name, spots) 
     {
		if ( !spots || spots.length == 0)
		{
			return;
		} 
        let that = this;

        let breakId = spots[0].breakIdentifier;
       
        console.log(bngAPI+"/arrangeSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
        HTTP.put( bngAPI+"/arrangeSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, spots)
            .then( response => 
            { 
                
                for ( var idx in that.days)
                {
                	let name = that.days[ idx];
                	let found = false;
	                for ( var iGrid in that.dataSet[name].gridAds)
	                {
	                	let grid = that.dataSet[name].gridAds[iGrid];
	                	if ( grid )
	                	{
		                	for ( var iBreak in grid.breaks)
		                	{
		                		let brk = grid.breaks[iBreak];
		                		
		                		if ( brk )
	                			{
			                		if ( brk.brk.identifier === breakId )
			                		{
			                		    brk.unfilteredSpots = spots;
			                		    brk.spots = spots;
			                			that.dataSet[name].time = new Date().getTime();
			     						
			                			that.update[name]++;
			                			found = true;
			                			break;
			                		}
		                		}	
		                		if ( found )
		                		{
		                			break;
		                		}
		                		 
		                	}
		                }
	                }
	            }
                

            }).catch(e => {
				   that.arrangeSpots ([breakId]);
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
		
	},
	processChange()
    {
     	//this.doFilterSpots();
    },
    copySelected()
    {
    	this.selectedCopies = [];
    	for (let i in this.selectedCopyObjects )
    	{
    		this.selectedCopies.push(this.selectedCopyObjects[i].name);
    	}
    },
    breakToClipboard( spot )
    {
    	let that = this;
        this.startLoader();
        //let dateStr = new Date(day).toISOString().split('T')[0];
        //alert(bngAPI+"/optimizeClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/null/"+dateStr+"/"+grid.id+"/"+durationLeft)
    	HTTP.post( bngAPI+"/toClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/break", spot)
    		 .then( response => 
             { 
                for ( let key in that.dataSet)
                {
                	if ( that.dataSet[key] && that.dataSet[key].date == spot.date && that.dataSet[key].media && that.dataSet[key].media.id == spot.mediaId)
                	{
                		that.dataSet[key].dayStats = response.data.dayStats;
                	}
                }
              
                var breakList = response.data.touchedBreaks;
                if ( ! (breakList === true))
                {
	                that.reloadBreaks( breakList );
	            }
	            if ( that.clbId )
            	{
            		that.loadClipboard(that.clbId)
            	}
	            that.stopLoader()
              }).catch(e => {
                    this.stopLoader();
                    showError(that.$toast, "optimizeCB: " + e.response.data, e);
					
          		 });
    },
    dayToClipboard( stationId, date )
    {
    	let that = this;
        this.startLoader();
        let dateStr = momentTZ(date).utcOffset(0, true).format().split('T')[0];
        let spot = { mediaId: stationId, date: dateStr };
        //alert(bngAPI+"/optimizeClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/null/"+dateStr+"/"+grid.id+"/"+durationLeft)
    	HTTP.post( bngAPI+"/toClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/day", spot)
    		 .then( response => 
             { 
                for ( let key in that.dataSet)
                {
                	if ( that.dataSet[key] && that.dataSet[key].date == spot.date && that.dataSet[key].media && that.dataSet[key].media.id == spot.mediaId)
                	{
                		that.dataSet[key].dayStats = response.data.dayStats;
                	}
                }
              
                var breakList = response.data.touchedBreaks;
                if ( ! (breakList === true))
                {
	                that.reloadBreaks( breakList );
	            }
	            if ( that.clbId )
            	{
            		that.loadClipboard(that.clbId)
            	}
	            that.stopLoader()
              }).catch(e => {
                    this.stopLoader();
                    showError(that.$toast, "optimizeCB: " + e.response.data, e);
					
          		 });
    },
    optimizeCB( channelId, id, day, grid, durationLeft )
    {
        let that = this;
        this.startLoader();
        let dateStr = momentTZ(day).utcOffset(0, true).format().split('T')[0];
        HTTP.get( bngAPI+"/optimizeClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/"+dateStr+"/"+grid.id+"/"+durationLeft)
    		 .then( response => 
             { 
                for ( let key in that.dataSet)
                {
                	if ( that.dataSet[key] && that.dataSet[key].date == day && that.dataSet[key].media && that.dataSet[key].media.id == channelId)
                	{
                		that.dataSet[key].dayStats = response.data.dayStats;
                	}
                }
              
                var breakList = response.data.touchedBreaks;
				
                if ( ! (breakList === true))
                {
	                that.reloadBreaks( breakList );
	            }
	            if ( that.clbId )
            	{
            		that.loadClipboard(that.clbId)
            	}
	            that.stopLoader()
              }).catch(e => {
                    this.stopLoader();
                    showError(that.$toast, "optimizeCB: " + e.response.data, e);
					
          		 });
    },
    filterCB( stationId, id, grid, durationLeft )
    {
        if ( !grid || !grid.id )
        {
        	this.CBFilter = null;
        	this.loadClipboard(id, stationId);
        	return;
        }
    	this.CBFilter = {stationId: stationId, id: id, grid: grid, gridId: grid.id, durationLeft: durationLeft}
    	if ( ! stationId )
    	{
    		stationId = this.station.id;
    	}
    	let that = this;
		let time = new Date().getTime();

        let dateStr = momentTZ(this.activeDate).utcOffset(0, true).format().split('T')[0];

   		let inventoryLoad = new Promise(function (resolve, reject) {
    	HTTP.get( invAPI+"/filterClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/null/"+grid.id+"/"+durationLeft)
    		 .then( response => 
             { 
               	var data = response.data;
               
               	for ( var iSpot in data )
        		{
        			let spot = data[iSpot];
        			if ( !that.allPrograms.includes(spot.gridName))
                	{
                		that.allPrograms.push(spot.gridName);
                	}
                	if ( !that.allClients.includes(spot.clientName))
                	{
                		that.allClients.push(spot.clientName);
                	}
                	if ( !that.allBreaks.includes(spot.breakTypeShort))
                	{
                		that.allBreaks.push(spot.breakTypeShort);
                	}
					if ( !that.allRateTypes.includes(spot.rateType))
                	{
                		that.allRateTypes.push(spot.rateType);
                	}
                	if ( !spot.productName )
                	{
                		spot.productName= "--";
                	}
                	if ( !that.allProducts.includes(spot.productName))
                	{
                		that.allProducts.push(spot.productName);
                	}
                	spot.copyId = (!spot.copyId)?0:spot.copyId;
                	spot.copyName = (!spot.copyId)?"--":spot.copyName;
                	if ( !that.allCopyIds.includes(spot.copyId))
                	{
                		that.allCopyIds.push(spot.copyId);
                	}
        		}
				that.loadCopies(that.allCopyIds).then( copies => {
               		
               		that.allCopies = copies;
	                that.clipboard = data;
	                that.dataSet[ id ] = data;
	
	                that.clbId = id;
	                let dur = (new Date().getTime()-time);
	               	resolve ();
				});
             }).catch(e => {
                    that.$toast.error("loading data for clipboard: " + e.response.data, 'Error', { position: "topRight" });
					reject ();
          		 });
   		});
    },
	getRawKey(spot)
     {
    	return spot.gridId+":"+spot.mediaId+":"+spot.breakNo+":"+spot.breakTypeId+":"+spot.date;
     },
    setSpotStatus( spot, status)
    {
    	 let that = this;
    	 let bookRequest = {"statusId": status.id, "ids": [spot.id] };
    	 HTTP.put( bngAPI+"/rebook/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
         .then( response => 
         { 
             var breakList = response.data.touchedBreaks;
             if ( !(breakList === true) )
             {
	                that.reloadBreaks ( breakList );
	            }
         }).catch(e => {
                 that.$toast.error(e.response.data, 'Error', { position: "topRight" });

         });
    	 
    },
    deleteSpot( toDelete, fromId, fromClipboard ) {
    	
 		if ( fromClipboard )
 		{
	  	    let that = this;
	  	    
			let time = new Date().getTime();
			let n = toDelete.length;
			let bookRequest = { "ids": toDelete };
	  		console.log(bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
			//console.log( JSON.stringify(toDelete ));
			HTTP.put( bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0", bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                
	                console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
	                that.reloadBreaks ( [fromId] );   
	                
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });
	      } else
	      {

			let bookRequest = {"statusId": this.unplaced.id, "ids": toDelete };
			console.log(JSON.stringify(bookRequest)) 
			let that = this;
			console.log( bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0");
			HTTP.put( bngAPI+"/rebook/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
		            .then( response => 
		            { 
		                var breakList = response.data.touchedBreaks;
		                if ( !(breakList === true) )
		                {
			                that.reloadBreaks ( breakList );
			            }
		            }).catch(e => {
		                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	
		            });
		      }
  	},
  	removeCopy(spots)
	{
		var ids = new Array();
		for ( let i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"copyId": 0, "ids": ids };
		console.log(JSON.stringify(bookRequest)) 
		let that = this;
		console.log( bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0");
		HTTP.put( bngAPI+"/rebook/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
	            .then( response => 
	            { 
       				var breakList = response.data.touchedBreaks;
	                if ( !(breakList === true) )
	                {
		                that.reloadBreaks ( breakList );
		            }
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
	markXChangeable(spots, exchangeable)
	{
		var ids = new Array();
		for ( let i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"exchangeable": exchangeable, "ids": ids };
		let that = this;
		//alert( bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0");
		HTTP.put( bngAPI+"/rebook/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
	            .then( response => 
	            { 
       				var breakList = response.data.touchedBreaks;
	                if ( !(breakList === true) )
	                {
		                that.reloadBreaks ( breakList );
		            }
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
	setPrice(spots, price)
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"fixedPrice": price, "ids": ids };
		console.log(JSON.stringify(bookRequest)) 
		let that = this;
		console.log( bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0");
		HTTP.put( bngAPI+"/rebook/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
	            .then( response => 
	            { 
	                var breakList = response.data.touchedBreaks;
	                if ( !(breakList === true) )
	                {
		                that.reloadBreaks ( breakList );
		            }
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
	moveSpots (dndType, spots, breakIdentifier, sourceIdentifier, fromId, toId) 
     {
		//alert( "moveSpots:: "+ breakIdentifier +" <-- "+ sourceIdentifier+ "/"+dndType)
		if ( !spots || !breakIdentifier)
		{
			return;
		} 
        let that = this;
        let time = new Date().getTime();
        let n = 1;

        let fromTo = { spots: spots, setToStatusId: 0, line: breakIdentifier };
        //console.log( bngAPI+"/moveSingleSpot/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/"+dndType );
        //console.log( JSON.stringify( fromTo));
        HTTP.put( bngAPI+"/moveSingleSpot/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/"+dndType, fromTo)
            .then( response => 
            { 
            	
            	let breakList = response.data.touchedBreaks;
                
                let dur = (new Date().getTime()-time);                
	            console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
	            that.reloadBreaks ( breakList );
                
            }).catch(e => {
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });

	},
	recalcStats(dayId)
	{
	    let data = this.dataSet[ dayId];
	    this.dataSet[ dayId].dayStats.revenueHC = 0;
	    this.dataSet[ dayId].dayStats.spots = 0;
		for ( let grid in data.gridAds )
		{
			let gridAds = data.gridAds[grid];
			for ( let brk in gridAds.breaks )
			{
				let aBreak = gridAds.breaks[brk]; 
				
				for ( let spotIdx in aBreak.spots )
				{
					let spot = aBreak.spots[spotIdx]; 
					this.dataSet[ dayId].dayStats.spots++;
					this.dataSet[ dayId].dayStats.revenueHC += spot.grossHC;
				}
			}
		}
	},
	moveBreak ( type, breakIdentifier, sourceIdentifier, fromId, toId) 
     {
     	if ( !breakIdentifier || !sourceIdentifier)
		{
			return;
		} 
        let that = this;
        let time = new Date().getTime();
        let n = 1;
        //let breakId = spots.breakId;

        
        let fromTo = { from: sourceIdentifier, to: breakIdentifier, type: type };
        console.log( bngAPI+"/moveBreak/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId );
        console.log( JSON.stringify( fromTo));
        HTTP.put( bngAPI+"/moveBreak/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, fromTo)
            .then( response => 
            { 
                var breakList = response.data.touchedBreaks;
                if ( breakList === true || sourceIdentifier === 'Clipboard' || breakIdentifier === 'Clipboard'  )
                {
                	that.loadDay(fromId, that.tz, time);
                	that.loadDay(toId, that.tz, time);
                	if ( that.clbId )
                	{
                		that.loadClipboard(that.clbId)
                	}
                }
                else
                {
	                let dur = (new Date().getTime()-time);                
	                console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
	                that.reloadBreaks( breakList );
	            }
                
            }).catch(e => {
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });

	},
	doCopyRotation( dayId ) {
		this.copyRotation( this.dataSet[dayId].media.id, this.dataSet[dayId].date);
	},
	doPositioning( dayId ) {
		this.breakPositioning( this.dataSet[dayId].media.id, this.dataSet[dayId].date);
	},
	doPositioningGrid( dayId ) {
		this.breakPositioningGrid( this.dataSet[dayId].media.id, this.dataSet[dayId].date);
	},
	doPositioningDay( dayId ) {
		this.breakPositioningDay( this.dataSet[dayId].media.id, this.dataSet[dayId].date);
	},
	doPositioningDayBM( dayId ) {
		this.breakPositioningDayBM( this.dataSet[dayId].media.id, this.dataSet[dayId].date);
	},
	removeFillerOnDay( dayId ) {
		this.removeFiller( this.dataSet[dayId].media.id, this.dataSet[dayId].date);
	},
	copyRotation( channelId, day)
	{
		this.pbAction = "copyRotationInternal";
    	this.pbInfo = "Run copy rotation";
    	this.pbChannelId=channelId;
    	this.pbDay=day;
  	    this.showProgressBar = true;
	},
    copyRotationInternal( myId, api )
	{
		let channelId = this.pbChannelId;
		let day = this.pbDay;
	    //this.startLoader();
		let that = this; 
		console.log(bngAPI+"/copyRotationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day) 
		api.put( bngAPI+"/copyRotationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day+"/"+myId)
            .then( response => 
            { 
            	
            	this.showProgressBar = false;
                var breakList = response.data.touchedBreaks;
                if ( ! (breakList === true))
                {
	                that.reloadBreaks( breakList );
	            }
	            //that.stopLoader()
                
            }).catch(e => {
            	 
            	   this.showProgressBar = false;
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
	},
	breakPositioning( channelId, day)
	{
	    this.startLoader();
		let that = this;
		console.log(bngAPI+"/positioningOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day) 
		HTTP.put( bngAPI+"/positioningOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day)
            .then( response => 
            { 
                var breakList = response.data.touchedBreaks;
                if ( ! (breakList === true))
                {
	                that.reloadBreaks( breakList );
	            }
	            that.stopLoader()
                
            }).catch(e => {
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
	},
	breakPositioningDay( channelId, day)
	{
		this.pbAction = "breakPositioningDayInternal";
    	this.pbInfo = "Run optimiser";
    	this.pbChannelId=channelId;
    	this.pbDay=day;
  	    this.showProgressBar = true;
	},
  	action( what, myId, api)
    {
    	this[what](myId, api )
    },
	breakPositioningDayInternal( myId, api )
	{
	    
		let that = this;
		//alert( api)
		//console.log(bngAPI+"/optimizationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day) 
		api.put( bngAPI+"/optimizationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.pbChannelId+"/"+this.pbDay+"/"+this.timing4Opt+"/"+this.optimizerSettings[this.pbChannelId].id+"/"+myId)
            .then( response => 
            { 
				//alert( JSON.stringify(response.data.dayStats) )
                for ( let key in that.dataSet)
                {
                	if ( that.dataSet[key] && that.dataSet[key].date == that.pbDay 
                	     && that.dataSet[key].media && that.dataSet[key].media.id === that.pbChannelId)
                	{
                		that.dataSet[key].dayStats = response.data.dayStats;
                		//lKey = key;
                	}
                }
                
                let breakList = response.data.touchedBreaks;
				//alert( JSON.stringify( breakList))
                if ( ! (breakList === true))
	            {
	                if ( breakList.length > that.MAX_CHANGES )
	                {
						that.reload();
					}
					else
					{
		                that.reloadBreaks( breakList );
		                that.loadClipboard(that.clbId);
		                that.showProgressBar = false;
		            }
		        }
	            
                 
            }).catch(e => {
            
                showError( that.$toast, e, "run optimization on day");
                that.showProgressBar = false;
            });
	}, 
	breakPositioningDayBM( channelId, day)
	{
	    this.startLoader();
		let that = this;
		console.log(bngAPI+"/optimizationOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day) 
		HTTP.put( bngAPI+"/optimizationOnDayBM/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+day+"/"+this.timing4Opt+"/"+this.optimizerSettings[channelId].id)
            .then( response => 
            { 
	            that.stopLoader();
	            let downloadResponse = response.data;
	              
	            that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
                
            }).catch(e => {
            	that.stopLoader()
                showError( that.$toast, "run in grid opt", e);
            });
	},
	removeFiller( channelId, day)
	{
		this.pbAction = "removeFillerInternal";
    	this.pbInfo = "Remove filler spots";
    	this.pbChannelId=channelId;
    	this.pbDay=day;
  	    this.showProgressBar = true;
	},
	removeFillerInternal( myId, api )
	{
	   let that = this;
		api.put( bngAPI+"/removeFillerOnDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.pbChannelId+"/"+that.pbDay+"/"+myId)
            .then( response => 
            { 
	            
                var breakList = response.data.touchedBreaks;
                if ( ! (breakList === true))
	            {
	                if ( breakList.length > that.MAX_CHANGES )
	                {
						that.loadStation( this.dayId);
					}
					else
					{
		                that.reloadBreaks( breakList );
		                that.loadClipboard(that.clbId);
		                that.showProgressBar = false;
		                
		            }
		        }
                
                
            }).catch(e => {
            	that.stopLoader()
                showError( that.$toast, "remove Filler", e);
            });
	},
	forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	breakPositioningOneBreak( breakKey)
	{
	    this.startLoader();
		let that = this;
		HTTP.put( bngAPI+"/positioningOneBreak/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+breakKey)
            .then( response => 
            { 
                var breakList = response.data.touchedBreaks;
                if ( ! (breakList === true))
                {
	                that.reloadBreaks( breakList );
	            }
	            that.stopLoader()
                
            }).catch(e => {
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
	},
	
	reloadBreaks(breakLines)
	{
		//alert( "rb: "+ JSON.stringify( breakLines));
		
		let that = this;
		var refArr = new Array();
		
		var loadCB = false;
        for ( let ii in breakLines )
        {
            if ( breakLines[ii].toUpperCase() == 'CLIPBOARD' )
            {
            	loadCB = true;
            }
            else
            {
        		refArr.push( breakLines[ii]);
       	 	}
       	}	 
        
        if ( loadCB )
        {
        	that.loadClipboard(that.clbId);
        	
        }
        if ( ! refArr.length )
        {
        	return;
        }
       
        var breakArr = new Array();
        for ( let ii in refArr )
        {
        	let breakId = refArr[ii];
        	let found = null;
	        for ( let idx in that.days)
			{
				let dayId = that.days[ idx];
				
	       		for ( let iAds in that.dataSet[ dayId].gridAds )
	       		{
	       			let ads = that.dataSet[ dayId].gridAds[iAds];
	       			found = ads.breaks.find( b => b.brk.identifier == breakId || b.brk.id== breakId);
					
	       			if ( found )
	       			{
						break;
	       			}
	       		}
	       		if ( found )
       			{
       				break;
       			}
			}
	        if ( found )
   			{
	        	breakArr.push( found.brk.identifier);
   			}
        }
       
        let lineInput = { lines: breakArr }
		//alert( "rb: "+ JSON.stringify( lineInput));
        HTTP.post( invAPI+"/getInventoryForBreaks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, lineInput)
        		 .then( response => 
                 { 
                   	let breaksToReplace = response.data;
					//alert( "rb: "+ JSON.stringify( breaksToReplace));
                   	for ( var i in breaksToReplace )
                   	{
                   		let brk = breaksToReplace[i];
                   		if ( brk && brk.brk )
                   		{
                   			//let bIdent = brk.brk.identifier.indexOf('.');
                   			let breakId = brk.brk.identifier;
                   			
                   			//alert( breakId +"-->"+ brk.spots.length)
	                   		for ( var idx in that.days)
                			{
                				let dayId = that.days[ idx];
                				//alert( that.dataSet[ dayId].gridAds.length)
		                   		for ( var iAds in that.dataSet[ dayId].gridAds )
		                   		{
		                   			let ads = that.dataSet[ dayId].gridAds[iAds];
		                   			//alert( breakId)
		                   			let found = ads.breaks.find( b => b.brk.id === brk.brk.id || b.brk.identifier == breakId );
		                   			//alert( breakId + "-->" +JSON.stringify(found));
		                   			
		                   			if ( found )
		                   			{
		                   				//alert( found.brk.identifier +"/"+ brk.spots.length +"/"+ found.spots.length)
		                   				found.spots = brk.spots;
		                   				found.unfilteredSpots = brk.spots;
		                   				for ( var iSpot in brk.spots )
				                		{
				                			let spot = brk.spots[iSpot];
				                			if ( !that.allPrograms.includes(spot.gridName))
						                	{
						                		that.allPrograms.push(spot.gridName);
						                	}
						                	if ( !that.allClients.includes(spot.clientName))
						                	{
						                		that.allClients.push(spot.clientName);
						                	}
						                	if ( !that.allBreaks.includes(spot.breakTypeShort))
						                	{
						                		that.allBreaks.push(spot.breakTypeShort);
						                	}
											if ( !that.allRateTypes.includes(spot.rateType))
						                	{
						                		that.allRateTypes.push(spot.rateType);
						                	}
						                	if ( !spot.productName )
						                	{
						                		spot.productName= "--";
						                	}
						                	if ( !that.allProducts.includes(spot.productName))
						                	{
						                		that.allProducts.push(spot.productName);
						                	}
						                	spot.copyId = (!spot.copyId)?0:spot.copyId;
						                	spot.copyName = (!spot.copyId)?"--":spot.copyName;
						                	if ( !that.allCopyIds.includes(spot.copyId))
						                	{
						                		that.allCopyIds.push(spot.copyId);
						                	}
				                		}
 
		                   				
				                		//XXXX
				                		//that.selectedCopies.push({}).pop();
		                   				that.dataSet[ dayId].time = new Date();
		                   				that.recalcStats(dayId)
		                   				that.update[ dayId]++;
		                   				that.counter++;
		                   				that.applyFilter();
		                   				break;
		                   			}
		                   		}
		                   	}
	                   	}
                   	}
                   	that.loadCopies(that.allCopyIds).then( copies => {
                   		that.allCopies = copies;
                   		that.allProducts.sort();
                		
                		that.allBreaks.sort();
                		that.allClients.sort();
                		that.allPrograms.sort();
                   	});
					
                 }).catch(e => {
                        that.$toast.error("loading inventory: " + e.response.data, 'Error', { position: "topRight" });

              		 });
	},
	loadCopies(ids)
	{
		let that = this;
		
		let loader = new Promise(function (resolve, reject) 
		{
			
			HTTP.post( fwAPI+"/getAllData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Copy", ids)
			 .then( response => 
	        { 
	           let data = response.data.data;
	           let copies = new Array();
	           for ( var ci in data )
		       {
		       	  let copy = data[ci];
		       	  copies.push( {id: copy.id, name: copy.name, label: copy.name+"/"+copy.duration+"/"+copy.code+"/"+copy.received, received: copy.received, locked: copy.lock?true:false });
		       }
	           if ( ids.find(p=>p==0) === 0 )
	           {
	        	   copies.push( {id: 0, name: "--", label: "--", locked: false, received: "2999-12-31" });
	           }
	           copies.sort(function(a,b) {
			     	let v1 = (a.lock?0:1)+"."+a.received+"."+a.name;
		            let v2 = (b.lock?0:1)+"."+b.received+"."+b.name;
		            
		            if ( v1 > v2 )
		            {
		                return -1;
		            }
		            if ( v1 < v2 )
		            {
		                return 1;
		            }
			     	return 0;
			     });
	           resolve(copies)
	        }).catch(e => {
	            that.$toast.error("loading copy data: " + e.response.data, 'Error', { position: "topRight" });
	            reject ();
	 		 });
	    });
		return loader;
	},
    loadStation( dayId, requestedChannel, activeDate )
    {
		
    	//alert( "loadStation")
		let promiseResolve, promiseReject;
    	let promise = new Promise(function(resolve, reject){
    		  promiseResolve = resolve;
    		  promiseReject = reject;
    		});
    	if ( !dayId)
		{
    		promiseResolve();
		}
		//let stationLoader = new Promise(function (resolve, reject) {
	    	let that = this;
	    	if ( !that.dataSet[ dayId])
	    	{
	    		that.dataSet[ dayId] = {};
	    	}
	    	let stationId = requestedChannel;
	    	if ( !this.update )
	    	{
	    		this.update = new Array();
	    	}
	    	if ( ! activeDate )
	    	{
	    		activeDate = this.activeDate;
	    	}
	    	that.activeDateForWidget[ dayId] = activeDate;
			
	    	if ( this.selectedTimezone && this.selectedTimezone.id )
	        {
	       	 	this.timezoneId = this.selectedTimezone.id;
	        }
	        if ( ! stationId )
	        {
	            if ( this.stationId )
	            {
	        		stationId = this.stationId;
		        }
		        else
		        {
		        	if ( this.selectedStation && this.selectedStation.id )
		        	{
		       	 		stationId = this.selectedStation.id;
		        	}
		        }
		    }
	        if ( !this.selectedTimezone  )
	        {
	       		this.timezoneId = 0;
	        }
	        
	        let time = new Date().getTime();
	        
	        let lDate= momentTZ(activeDate).utcOffset(0, true).format().split('T')[0];
	    	let stationLoad = new Promise(function (resolve, reject) {
	            HTTP.get( gridAPI+"/getStations/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+lDate)
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		console.log("LOADED STATIONS in " + dur +"ms");  
	                    let allStations = response.data; //.map(p=>{return  {id: p.id, label: p.name, visible: true}});
	                    that.stations = [];
	                   
	                    for ( let channel in allStations )
	                    {
	                    	if ( allStations[channel].visible )
	                    	{
	                    		that.stations.push( allStations[channel]);
	                    		if ( allStations[channel].id == stationId || ! stationId)
		                    	{
		                    		that.selectedStation = allStations[channel];
		                    		
		                    		that.stationId = that.stations[channel].id;
		                    		stationId = that.stations[channel].id;
		                    	}
	                    	}
	                    	
	                    }    
	                    
	                    resolve (stationId);                    
	                 }).catch(e => {
	                        that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                        reject ();
	                    });
		      });
		      stationLoad.then( selectedStationId => {
		        let timezoneLoad = new Promise(function (resolve, reject) {
			   	    console.log(invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+selectedStationId+"/"+that.timezoneId);
			        HTTP.get( invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+selectedStationId+"/"+that.timezoneId)
		        		 .then( response => 
		                 {  
		                    let dur = (new Date().getTime()-time);
	               		    console.log("LOADED STATION "+response.data.channel.shortname+" in " + dur +"ms");  
		                    that.station = response.data.channel;
							that.activeChannelForWidget[ dayId] = that.station;
		                    that.tz = response.data.timezone;
		                    that.tz2 = response.data.secondTimezone;
		                    that.preempted = response.data.preempted;
		                    that.unplaced = response.data.unplaced;
		                    that.placed = response.data.placed.filter(p=>!p.aired);
		                    
		                    that.optimizerSettings[that.station.id] = response.data.optimizerSettings;
		                    if ( response.data.optimizerSettings )
		                    {
		                    	//let dur = momentTZ.duration(response.data.optimizerSettings.timeInMs);
		                    	that.timing4Opt = Math.round(response.data.timeOptInMs / 100);
		                    }
		                    that.preemptionReasons = response.data.reasons;
		                    that.timezones = [];  //response.data.allTimezones;
		                    for ( let t in response.data.allTimezones)
		                    {
		                    	that.timezones.push( {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name});
		                    	if ( response.data.allTimezones[t].id == that.timezoneId )
		                    	{
		                    		that.selectedTimezone = {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name};
		                    	}
		                    }
	
		                    resolve (response.data.timezone);
		                 }).catch(e => {
		                        that.$toast.error("loading data for timezones: " + e.response.data, 'Error', { position: "topRight" });
								reject ();
		                    });
		
		           });
		          timezoneLoad.then( timeZone => {
		        	   console.log("that.loadDay( "+dayId+","+timeZone+","+time+","+activeDate+","+stationId+")");
		           	   
		           	   that.loadDay( dayId, timeZone, time, activeDate, stationId).then( x => {
		           				promiseResolve();
		        		   }).catch( x => {
		        			    promiseReject();
		        		   })
		          }).catch( x => {
      			    promiseReject();
      		   })
		           
		      });
	
		return promise;
    },
    preempt( spots, reason)
    {
    	var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"statusId": this.preempted.id, "reasonId": reason.id, "ids": ids };
		console.log(JSON.stringify(bookRequest)) 
		let that = this;
		console.log( bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0");
		HTTP.put( bngAPI+"/rebook/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
	            .then( response => 
	            { 
	                var breakList = response.data.touchedBreaks;
	                if ( !(breakList === true) )
	                {
		                that.reloadBreaks ( breakList );
		            }
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
    },
    statusUpdate( dateStr, channelId, state )
    {
    	for ( var idx in this.days)
		{
			let dayId = this.days[ idx];
			let data = this.dataSet[ dayId];
			
       		if ( data.date === dateStr && data.media.id === channelId )
   			{
   				data.status = state;
   				this.$forceUpdate();
   			}
       	}
    },
    changeState( dateStr, channelId, state )
    {
        let that = this;
    	//alert(invAPI+"/setDayStatus/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/"+state.id);
        HTTP.put( invAPI+"/setDayStatus/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/"+state.id)
		 .then( response => 
         { 
            that.statusUpdate( dateStr, channelId, state )
           
          }).catch(e => {
            that.$toast.error("set day status " + e.response.data, 'Error', { position: "topRight" });

  		 });
    	
    },
    resetFilter() {
    	let that = this;
        that.selectedPrograms = new Array();
    	that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedProducts = new Array();
    	that.selectedBreaks = new Array();

    },
    applyFilter() {
        let that = this;
		let tmp = that.selectedClients;
		that.selectedClients = new Array();
		this.$set( this, "selectedClients", tmp);
    },
    loadDay( dayId, timeZone, time, activeDate, stationId)
	{
    	
    	let promiseResolve, promiseReject;
    	let promise = new Promise(function(resolve, reject){
    		  promiseResolve = resolve;
    		  promiseReject = reject;
    		});
		this.pbAction = "loadDayInternal";
		let dateISO = momentTZ(activeDate).utcOffset(0, true).format().split('T')[0];
    	this.pbInfo = "Reading day "+ dateISO;
    	this.pbOptions = {dayId: dayId, timeZone: timeZone, time: time, activeDate: activeDate, stationId: stationId, 
    			promiseResolve: promiseResolve, promiseReject: promiseReject};
    	
  	    this.showProgressBar = true;
    	
    	return promise;
	},
	loadDayInternal( myId, api )
    {
		//this.startLoader()
		
		let dayId = this.pbOptions.dayId;
		let timeZone = this.pbOptions.timeZone;
		let time = this.pbOptions.time;
		let activeDate = this.pbOptions.activeDate;
		let stationId = this.pbOptions.stationId;
		let promiseResolve = this.pbOptions.promiseResolve;
		let promiseReject = this.pbOptions.promiseReject;
		//let api = HTTP;
		//let myId = null;
        if ( !dayId )
        {
        	this.showProgressBar = false;
        	promiseReject();
        	return;
        }
    	let that = this;
		if ( ! activeDate )
    	{
    		activeDate = this.activeDate;
    	}
        that.allRateTypes = new Array();
    	that.allPrograms = new Array();
    	that.allCopies = new Array();
    	that.allCopyIds = new Array();
    	that.allClients = new Array();
    	that.allProducts = new Array();
        let dateStr = momentTZ(activeDate).utcOffset(0, true).format().split('T')[0];
        if ( !stationId )
		{
			stationId = that.station.id;
		}
        let startTime = new Date().getTime();
        let showEmptyBreaks = false;
		
   		//alert(invAPI+"/getInventoryForDay/playlist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/"+timeZone.id+"/"+that.timezoneId+"/"+dateStr+"/"+myId);
        api.get( invAPI+"/getInventoryForDay/playlistnew/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/"+timeZone.id+"/"+that.timezoneId+"/"+dateStr+"/"+myId)
    		 .then( response => 
             { 
				let dur = (new Date().getTime()-startTime);
				console.log( "getInventoryForDay> "+ (dur)+"ms");
               	let data = response.data;
               	showEmptyBreaks = showEmptyBreaks || (response.data.dayStats && response.data.dayStats.spots === 0);
               	that.station = data.media;
               	that.user = data.user;
                that.columnsRD = that.user.spotListColumnsRD;
 				that.columnsCB = that.user.spotListColumnsCB;
 				//alert(JSON.stringify(that.columnsCB))
				
               	for ( var iGrid in data.gridAds)
                {
                	let grid = data.gridAds[iGrid];
					
                	if ( grid )
                	{
						
	                	for ( var iBreak in grid.breaks)
	                	{
	                		let brk = grid.breaks[iBreak];
	                	
	                		if ( brk )
                			{
	                			
	                			if ( !that.allPrograms.includes(grid.detail.name))
			                	{
			                		that.allPrograms.push(grid.detail.name);
			                	}
								
                			    if ( !that.allBreaks.includes(brk.type.shortname))
			                	{
			                		that.allBreaks.push(brk.type.shortname);
			                	}
								
                			    brk.unfilteredSpots = brk.spots;
		                		for ( var iSpot in brk.spots )
		                		{
		                		   
		                			let spot = brk.spots[iSpot];
		                			
				                	if ( !that.allClients.includes(spot.clientName))
				                	{
				                		that.allClients.push(spot.clientName);
				                	}
									if ( !that.allRateTypes.includes(spot.rateType))
				                	{
				                		that.allRateTypes.push(spot.rateType);
				                	}
				                	if ( !spot.productName )
				                	{
				                		spot.productName= "--";
				                	}
				                	if ( !that.allProducts.includes(spot.productName))
				                	{
				                		that.allProducts.push(spot.productName);
				                	}
				                	if ( !spot.copyName )
				                	{
				                		spot.copyName= "--";
				                	}
				                	spot.copyId = (!spot.copyId)?0:spot.copyId;
				                	spot.copyName = (!spot.copyId)?"--":spot.copyName;
				                	if ( !that.allCopyIds.includes(spot.copyId))
				                	{
				                		that.allCopyIds.push(spot.copyId);
				                	}
		                		}
	                		}	
	                	}
	                }
                }
               	let xtime = new Date().getTime();
               	that.loadCopies(that.allCopyIds).then( copies => {
               		
               		that.allCopies = copies;
               		that.allProducts.sort();
            		
            		that.allBreaks.sort();
            		that.allClients.sort();
            		that.allPrograms.sort();
					that.allRateTypes.sort();
                		
                    that.dataSet[ dayId] = data;
                    //alert( dayId +"->"+data.date)
                    let dur = (new Date().getTime()-xtime);
                    
                   	//alert(dur)
                    that.loadClipboard( that.clbId, stationId, data.dangling );
                    that.update[ dayId]++;
                    that.counter++;
                    
                    that.showProgressBar = false;
                    that.$forceUpdate();
                    that.showEmptyBreaks = showEmptyBreaks;
                    promiseResolve();
                    //that.stopLoader()
               	});
               	
             }).catch(e => {
                    that.showProgressBar = false;
                    promiseReject();
                    showError( that.$toast, "loading rundown data ", e);
          		 });
	         
    },
    userUpdated( user) { 
    		let that = this;
    		this.user = user  
    		console.log("User update")
			HTTP.put( userAPI+"/setUserSpotColumn/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.user)
            .then( response => 
            { 
 				that.user = response.data;
 				that.columnsRD = that.user.spotListColumnsRD;
 				that.columnsCB = user.spotListColumnsCB;
            }).catch(e => {
                    alert(JSON.stringify(e));

            });
    },
    loadClipboard(id, stationId, dangling)
    {
        if ( ! stationId )
    	{
    		stationId = this.station.id;
    	}
        if ( this.CBFilter && this.CBFilter.gridId && stationId == this.CBFilter.stationId)
        {
        	this.filterCB( stationId, id, this.CBFilter.grid, this.CBFilter.durationLeft )
        	return; 
        }
       
    	
    	let that = this;
		let time = new Date().getTime();
        let dateStr = momentTZ(this.activeDate).utcOffset(0, true).format().split('T')[0];

   		let inventoryLoad = new Promise(function (resolve, reject) {
    	HTTP.get( invAPI+"/getClipboard/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/"+dateStr)
    		 .then( response => 
             { 
               	var data = response.data;
				
               	data.sort((x,y)=>(x.invalid === y.invalid)? 0 : x.invalid? 1 : -1)
               	for ( var iSpot in data )
        		{
        			let spot = data[iSpot];
        			if ( !that.allPrograms.includes(spot.gridName))
                	{
                		that.allPrograms.push(spot.gridName);
                	}
					if ( !that.allRateTypes.includes(spot.rateType))
                	{
                		that.allRateTypes.push(spot.rateType);
                	}
                	if ( !that.allClients.includes(spot.clientName))
                	{
                		that.allClients.push(spot.clientName);
                	}
                	if ( !that.allBreaks.includes(spot.breakTypeShort))
                	{
                		that.allBreaks.push(spot.breakTypeShort);
                	}
                	if ( !spot.productName )
                	{
                		spot.productName= "--";
                	}
                	if ( !that.allProducts.includes(spot.productName))
                	{
                		that.allProducts.push(spot.productName);
                	}
                	spot.copyId = (!spot.copyId)?0:spot.copyId;
                	spot.copyName = (!spot.copyId)?"--":spot.copyName;
                	if ( !that.allCopyIds.includes(spot.copyId))
                	{
                		that.allCopyIds.push(spot.copyId);
                	}
        		}
               	
				that.loadCopies(that.allCopyIds).then( copies => {
               		
               		that.allCopies = copies;
	                that.clipboard = data;
	                //alert( JSON.stringify(data))
	                that.dataSet[ id ] = data;
	
	                that.clbId = id;
					that.clipboardLoading = false;
	                let dur = (new Date().getTime()-time);
	               	console.log("LOADED clipboard "+dateStr+", "+ that.station.shortname+" in " + dur +"ms");
	                resolve ();
				});
             }).catch(e => {
                    that.$toast.error("loading data for clipboard: " + e.response.data, 'Error', { position: "topRight" });
					reject ();
          		 });
   		});
	         
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	async restore( restoredView)
	{
		for ( let x in restoredView )
    	{
    	   
    		this.activeDate = new Date(restoredView[x].date);
    		let id = this.addDay( restoredView[x].type, true, restoredView[x].channel );
			if ( restoredView[x].type != 'clipboard' )
			{
				
    			await this.loadStation( id, restoredView[x].channel, restoredView[x].date );
			}
    	}
	}
  },
  created() { 
    let restoredView = restore();
    this.timezoneId = sessionStorage.timezoneId;
    this.filterOptions = ["All","Only Rundown","Only Clipboard","none"];
    this.filterFor = "All";
    if ( this.$route.query.stationId && this.$route.query.day )
    {
    	this.activeDate = new Date(this.$route.query.day);
	  	let id = this.addDay( 'day', true);
	  	
	  	this.loadStation(id, parseInt(this.$route.query.stationId), this.activeDate);
    }
    else if ( restoredView.length && restoredView.length )
    {
    	
        this.restore( restoredView)
    } 
	else
	{
		
	    this.activeDate = new Date();
  		this.addDay( 'day', true);
  		this.loadStation( this.dayId);
	}
    initAppMode();
  },
  beforeDestroy() {
  	this.storeSettings();
  },
  updated() {
  	
 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}

.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.smallText {
  font-size: 8pt;
  height: 10pt;
}
.BMSection {
    margin-top: 3pt;
	height: 16pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #888; 
	//background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  1pt; 
    padding-bottom:  1pt !impprtant; 
    padding-left: 0pt;
    text-align: center;
	width: 100%;
}
</style> 